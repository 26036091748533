// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_gridItem__HHRlc img {\n  width: 150px;\n}\n.styles_gridItem__HHRlc > div {\n  box-shadow: none !important;\n}\n@media (max-width: 600px) {\n  .styles_gridItem__HHRlc {\n    margin: 15px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/SelectReadabilityLevelPage/styles.module.scss","webpack://./src/styles/totm/_mixins.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;AAFJ;AAIE;EAGE,2BAAA;AAFJ;ACLI;EDAJ;IAUI,YAAA;EADF;AACF","sourcesContent":["@import 'styles/totm/_mixins';\n\n.gridItem {\n  img {\n    width: 150px;\n  }\n  & > div {\n    -webkit-box-shadow: none;\n    -moz-box-shadow: none;\n    box-shadow: none !important;\n  }\n  @include respond-to(xs) {\n    margin: 15px;\n  }\n}\n","@mixin respond-to($media) {\n  @if $media == xs {\n    @media (max-width: 600px) {\n      @content;\n    }\n  } @else if $media == sm {\n    @media (max-width: 768px) {\n      @content;\n    }\n  } @else if $media == lm {\n    @media (max-width: 1280px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridItem": "styles_gridItem__HHRlc"
};
export default ___CSS_LOADER_EXPORT___;
