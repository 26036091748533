import { useParams } from 'react-router-dom'
import { totmContentBaseUrl } from 'config'
import SelectionCardGrid, {
  SelectionCardGridItem,
} from 'components/SelectionCardGrid'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import LoadingOverlay from 'components/LoadingOverlay'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

export default () => {
  const { readability } = useParams()
  const history = useHistory()
  const themes = useSelector((s) => s.classroom.themes)
  return (
    <LoadingOverlay loading={!themes?.[readability]}>
      <SelectionCardGrid>
        {Object.values(themes?.[readability]?.themes || [])
          .filter((o) => o.enabled)
          .map(({ name: category, thumbnail }) => (
            <SelectionCardGridItem
              className={styles.category}
              key={category}
              onClick={() => {
                history.push(`/levels/${readability}/category/${category}`)
              }}
              coverImage={`${totmContentBaseUrl}/${thumbnail}`}
            />
          ))}
      </SelectionCardGrid>
    </LoadingOverlay>
  )
}
