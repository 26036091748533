import cn from 'classnames'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'

const parseWordPatternFromPdfFilename = (pdfFilename) => {
  return pdfFilename?.replace(/_Card.pdf$/, '')?.toLowerCase()
}

const PageText = ({ bookInfo, wordRefs = [], words, className, wordIndex, wordHint = null }) => {
  const { toolbox } = useSelector((s) => s.book)
  const scaffoldWordPattern = parseWordPatternFromPdfFilename(
    toolbox?.scaffoldData?.pattern
  )
  const { targetSightWords = [], targetWordPatterns = [] } = bookInfo
    ? bookInfo.getTargets()
    : {}

  const matchTarget = (word, index) => {
    if (scaffoldWordPattern && index === wordIndex) {
      return {
        type: 'wordPattern',
        match: scaffoldWordPattern,
      }
    }
    const match = targetSightWords.find((sightWord) => {
      return sightWord.toLowerCase() === word.toLowerCase().replace(/\W*$/, '')
    })
    if (match) {
      return {
        type: 'sightWord',
        match,
      }
    }
    return {
      type: 'wordPattern',
      match: targetWordPatterns.find((wordPattern) =>
        word.toLowerCase().includes(wordPattern.toLowerCase())
      ),
    }
  }

  const withMarkup = (word, index) => {
    const { match, type } = matchTarget(word, index)
    if (!match) {
      return word
    }
    return word.replace(
      new RegExp(`(${match})`, 'gi'),
      `<span class=${styles[type]}>$1</span>`
    )
  }

  let renderWord = (word, index) => (
    wordHint === 'Seen Before' ? (
      <div
        style={{lineHeight:1}}
        key={index}
        ref={wordRefs[index]}
        className={cn(styles.word, ["mr-2"])}
      >
        <div style={{height:20, width:'100%', textAlign:'center'}}>
        <div dangerouslySetInnerHTML={{ __html: withMarkup(word, index) }}/>
        {wordIndex === index && (
          <img src={require('assets/slider.png')} style={{width:'100%', height:25, marginTop:5}}/>
        )}
        </div>
      </div>
    ):(
      <span
        key={index}
        ref={wordRefs[index]}
        className={cn(styles.word, [{ [styles.active]: wordIndex === index }])}
        dangerouslySetInnerHTML={{ __html: withMarkup(word, index) }}
      />

    )
  )

  return (
    <div className={cn(styles.textContainer, className)}>
      {words.map(renderWord)}
    </div>
  )
}

export default PageText
