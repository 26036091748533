//https://developer.mozilla.org/es/docs/Web/API/Web_Speech_API/Uso_de_la_Web_Speech_API
import React, { useEffect } from 'react'
import classNames from 'classnames'
import curry from 'lodash/curry'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useReaderProvider } from 'context/ReaderContext'
import readAloud from 'assets/read-aloud.png'
import styles from './styles.module.scss'
import cn from 'classnames'
import { speak } from 'utils'

const SelectChildren = ({ className, students }) => {
  const history = useHistory()
  const {
    readers,
    currentSelectedChildIndex,
    setReaderData,
    mode
  } = useReaderProvider()

  const handleClick = curry((person, evt) => {
    setReaderData(currentSelectedChildIndex, person)
    history.push('/select-avatar')
  })

  const canSelectStudent = (student) => {
    return !readers.find((o) => o.ID === student.ID)
  }

  const studentsList = mode === 'Individual' ? students : students.filter(canSelectStudent)

  return (
    <div className={cn('centered-card-layout', className)}>
      <div className={cn('card', styles.cardContainer)}>
        <div className={styles.cardHeader}>Find your name</div>
        <div className={styles.cardBody}>
          {studentsList.map((student, index) => (
            <div
              key={index}
              className={classNames(
                styles.childRow,
                'd-flex align-items-center'
              )}
            >
              <span
                onClick={handleClick(student)}
                className={styles.childRowLabel}
              >
                {student.display_name}
              </span>{' '}
              <img
                src={readAloud}
                alt=""
                onClick={() => {
                  speak(student.display_name)
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default connect((state) => ({ ...state.classroom }))(SelectChildren)
