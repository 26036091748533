export const apiUrl = process.env.REACT_APP_SERVER_DOMAIN

export const readabilityLevels = ['01_Circle', '03_Triangle', '04_Square']

export const SoundBySoundColors = [
  '#5aead6',
  '#ec6a34',
  '#9570ae',
  '#bc0016',
  '#51a0f3',
]

export const totmContentBaseUrl =  process.env.REACT_APP_POWERTOOLS_CDN || 'https://powertools-web-cdn.s3.amazonaws.com'

export const readingModes = {
  INDIVIDUAL_MODE: 1,
  BUDDY_MODE: 2,
  GROUP_MODE: 3,
}

export const maxScaffoldTries = 2

export const videoAutoStartAfter = 5000

export const ScaffoldTypes = {
  READ_ALOUD: 'ReadAloud',
  SEEN_BEFORE: 'SeenBefore',
  SOUND_BY_SOUND: 'SoundBySound',
  WORD_PATTERN: 'WordPattern',
}
