import { randInt, getRectCenterPos } from 'utils'
import buddyCheckImage from 'assets/buddy_check.PNG'
import cn from 'classnames'
import { useMemo, createRef, useState, useEffect, useCallback } from 'react'
import { DraggableCore as Draggable } from 'react-draggable'
import styles from './styles.module.scss'
import { useDimensions } from 'context/Dimensions'

const getElementBoundingClientRect = ({ ref }) => {
  return ref.current?.getBoundingClientRect() || {}
}

const snapBox = { x: 120, y: 60 }

const RESPONSE_CORRECT = 1
const RESPONSE_INCORRECT = 2

const ComprehensionCheckTypeC = ({ words, handleSubmit }) => {
  const dimensions = useDimensions()
  const isUsedWord = (v) => !v.not_used
  const isPlaceholder = (v) => v.placeholder
  
  const [response, setResponse] = useState(null)
  const [wordHandlesOrigin, setWordHandlesOrigin] = useState([])

  const [handleCenterOffset, setWordHandlesCenterOffset] = useState({
    x: 0,
    y: 0,
  })
  const [sentencePlaceholderRects, setSentencePlaceholderRects] = useState([])
  const wordHandles = useMemo(() => {
    return words.filter(isPlaceholder).map((word) => ({
      ...word,
      ref: createRef(null),
    }))
  }, [words])
  const sentenceWords = useMemo(() => words.filter(isUsedWord), [words])
  
  const sentencePlaceholders = useMemo(() => {
    const sentencePlaceholders = []
    for (let i in sentenceWords) {
      if (isPlaceholder(sentenceWords[i])) {
        sentencePlaceholders.push({
          ...sentenceWords[i],
          sentenceIndex: i,
          ref: createRef(null),
        })
      }
    }
    return sentencePlaceholders
  }, [sentenceWords])

  let initSolution = useMemo(
    () => sentenceWords.map((v) => (v.placeholder ? null : v.text)),
    [sentenceWords]
  )

  const [currentSolution, setCurrentSolution] = useState(initSolution)
  let randomXList = [randInt(-60, -40), randInt(10, 20), randInt(40, 50)]
  let randomYList = [randInt(1, 6), randInt(7, 13), randInt(14, 20)]
  const resetWordHandles = useCallback(() => {
    const handleWordRect = getElementBoundingClientRect(wordHandles[0])
    const stageWidth = dimensions.width - handleWordRect.width * 3
    const stageCenterX = dimensions.width / 2
    const wordHandlesRandPoints = wordHandles.map((v, i) => ({
      x: stageCenterX + (randomXList[i] * stageWidth) / 100,
      y: (randomYList[i] * dimensions.height) / 100,
    }))
    wordHandles.forEach(({ ref }, i) => {
      ref.current.style.position = 'absolute'
      ref.current.style.left = `${wordHandlesRandPoints[i].x}px`
      ref.current.style.top = `${wordHandlesRandPoints[i].y}px`
      ref.current.style.transform = null
    })

    setWordHandlesOrigin(wordHandlesRandPoints)
    setResponse(null)
  }, [wordHandles, dimensions])

  useEffect(() => {
    const handleWordRect = getElementBoundingClientRect(wordHandles[0])

    setWordHandlesCenterOffset({
      x: handleWordRect.width / 2,
      y: handleWordRect.height / 2,
    })
    setSentencePlaceholderRects(
      sentencePlaceholders.map(getElementBoundingClientRect)
    )
    resetWordHandles()
  }, [wordHandles, resetWordHandles, sentencePlaceholders])

  const handleCheck = () => {
    if (response === RESPONSE_INCORRECT) {
      setResponse(null)
      setCurrentSolution(initSolution)
      resetWordHandles()
    }
    handleSubmit(response === RESPONSE_CORRECT)
  }

  const checkSolution = (solution) => {
    for (let v of solution) {
      if (!v) {
        setResponse(null)
        return
      }
    }
    for (let i in solution) {
      if (sentenceWords[i].text !== solution[i]) {
        setResponse(RESPONSE_INCORRECT)
        return
      }
    }
    setResponse(RESPONSE_CORRECT)
  }

  const updateCurrentSolution = (target) => {
    const nextSolution = currentSolution.map((value, index) => {
      let r = value
      if (index.toString() === target.index) {
        r = target.isOutOfBounds ? null : target.value
      } else if (value && value.toString() === target.value) {
        r = null
      }
      return r
    })
    setCurrentSolution(nextSolution)
    checkSolution(nextSolution)
  }

  const handleIntersectedSentencePlaceholder = (index) => {
    const { ref: handleRef } = wordHandles[index]
    const handleRect = getElementBoundingClientRect({ ref: handleRef })
    const { x, y } = handleRect
    for (let i in sentencePlaceholderRects) {
      let r = sentencePlaceholderRects[i]
      if (
        x > r.x - snapBox.x &&
        x < r.x + snapBox.x &&
        y > r.y - snapBox.y &&
        y < r.y + snapBox.y
      ) {
        const { sentenceIndex } = sentencePlaceholders[i]
        updateCurrentSolution({
          index: sentenceIndex,
          value: wordHandles[index].text,
        })
        const sentenceWordRect = getElementBoundingClientRect(
          sentencePlaceholders[i]
        )
        const handleCenter = getRectCenterPos(handleRect)
        const sentenceWordCenter = getRectCenterPos(sentenceWordRect)
        const offset = {
          x: sentenceWordCenter.x - handleCenter.x,
          y: sentenceWordCenter.y - handleCenter.y,
        }
        handleRef.current.style.transform += ` translate(${offset.x}px,${offset.y}px)`
        break
      } else {
        updateCurrentSolution({
          index: sentencePlaceholders[i],
          value: wordHandles[index].text,
          isOutOfBounds: true,
        })
      }
    }
  }


  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div
          className={styles.buddyCheckImageContainer}
          onClick={() => handleCheck()}
          style={{ display: response ? 'block' : 'none' }}
        >
          <img
            src={buddyCheckImage}
            className={styles.buddyCheckImage}
            alt=""
          />
        </div>
      </div>
      {wordHandles.map(({ text, placholder }, index) => (
        <Draggable
          key={index}
          handle={`.handle${index}`}
          onDrag={(e, d) => {
            d.node.style.transform = `translate(
              ${d.x - handleCenterOffset.x - wordHandlesOrigin[index].x}px,
              ${d.y - handleCenterOffset.y - wordHandlesOrigin[index].y}px
            )`
          }}
          onStop={(e, d) => {
            handleIntersectedSentencePlaceholder(index)
          }}
        >
          <div
            ref={wordHandles[index].ref}
            className={cn(`handle${index}`, styles.wordItemContainer, [
              { [styles.notUsed]: wordHandles[index].not_used },
            ])}
            style={{ position: 'absolute'}}
          >
            <div className={styles.wordItem}>{text}</div>
          </div>
        </Draggable>
      ))}
      <div className={styles.placeholdersContainer}>
        {(() => {
          let i = 0
          return sentenceWords.map((v) => {
            return v.placeholder ? (
              <div
                ref={sentencePlaceholders[i++].ref}
                className={styles.placeholderItem}
                key={i}
              >
                &nbsp;
              </div>
            ) : (
              <span>{v.text}&nbsp;</span>
            )
          })
        })()}
      </div>
    </div>
  )
}

export default ComprehensionCheckTypeC
