export const VERSION = 0.9;
export const FOOTER_VERSION = "2.0(11)";

// Mic Required Popup
export const MIC_REQUIRED_TITLE = `Microphone Access Required`
export const MIC_REQUIRED_MESSAGE = `PowerTools needs access to your microphone to be able to record
audio of children's speech while they draw and write in the app.`

// User Type Popup -> @School or @Home
export const USER_TYPE_TITLE = `Which type of PowerTools user are you?`
export const HOME_USER_TYPE = `I am using PowerTools at home`
export const SCHOOL_USER_TYPE = `I am using PowerTools at school`

// @Home Student Message Popup
export const AUDIO_MESSAGES_TITLE = ( messagesLength ) => (
    messagesLength > 1 ?
    `You have new audio messages from your teacher`
    :
    `You have a new audio message from your teacher`
)
export const AUDIO_MESSAGE_THUMBNAIL_URL = "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRQaS0TiPKvfUl8sQrXaiGgTXSgmMWhnWPJdw&usqp=CAU"

// Loading Student Popup
export const LOADING_STUDENT_TITLE = "Please Wait!"
export const LOADING_STUDENT_MESSAGE = "Getting students"

// Gallery Select a Student Title
export const GALLERY_SELECT_STUDENT = "Select a Student"

// Gallery Loading Student Sessions
export const GALLERY_SESSIONS_TITLE = "Please Wait!"
export const GALLERY_SESSIONS_MESSAGE = "Getting Sessions."

export const NO_POWERTOOLS_LICENSE = {
    "NOTSUBSCRIBED":{
        "header": "No Subscription Found",
        "body":"There does not seem to be a subscription for you. Don't worry though - we'd be happy to help you get up and running in no time. Please contact Tools of the Mind support below to help with renewing your subscription."
    },
    "EXPIRED":{
        "header": "Subscription Expired",
        "body":"Your subscription to PowerTools appears to have expired! Don't worry though - we'd be happy to help you get back up and running in no time. Please contact Tools of the Mind support below to help with renewing your subscription."
    }
}