import ReadAloudButton from 'components/ReadAloudButton'
import styles from './styles.module.scss'
import { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import { bounce } from 'react-animations';


function ComprehensionCheckTypeB({ image1, image2, audio, handleSubmit }) {
  const bounceAnimation = keyframes`${bounce}`;
  const [ shuffledList, setShuffledList ] = useState([0,1])

  const shuffleNumList = (arr) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    setShuffledList(shuffled)
  }
  
  useEffect(()=>{
    const arr = [{ index: 0, image: image1 }, { index:1, image:image2 }];
    shuffleNumList(arr)
  }, [])

  const [ audioPlayed, setAudioPlayed ] = useState(false)
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer} onClick={() => handleSubmit(shuffledList[0]['index'])}>
        <img src={shuffledList[0]['image']} alt="" />
      </div>
      <div className={styles.centerContainer}>
        <BouncyDiv>
          <ReadAloudButton src={audio} className={styles.readAloudButton} onEnded={()=>setAudioPlayed(true)} />
        </BouncyDiv>
      </div>
      <div className={styles.rightContainer}>
        <img src={shuffledList[1]['image']} onClick={() => handleSubmit(shuffledList[1]['index'])} alt="" />
      </div>
    </div>
  )
}

export default ComprehensionCheckTypeB

const bounceAnimation = keyframes`${bounce}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
`;
