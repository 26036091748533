// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__UoQGF {\n  cursor: pointer;\n  width: 60%;\n}\n\n.styles_button__UoQGF:hover,\n.styles_button__UoQGF:active,\n.styles_button__UoQGF.styles_focus__-CD95 {\n  border-radius: 15%;\n}\n\n.styles_button__UoQGF:hover {\n  background-color: #f2763741;\n}\n\n.styles_button__UoQGF:active {\n  box-shadow: 0 0 0 2px #f2763741;\n}", "",{"version":3,"sources":["webpack://./src/components/ReadAloudButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;AACF;;AACA;;;EAGE,kBAAA;AAEF;;AACA;EACE,2BAAA;AAEF;;AACA;EACE,+BAAA;AAEF","sourcesContent":[".button {\n  cursor: pointer;\n  width:60%;\n}\n.button:hover,\n.button:active,\n.button.focus {\n  border-radius: 15%;\n}\n\n.button:hover {\n  background-color: #f2763741;\n}\n\n.button:active {\n  box-shadow: 0 0 0 2px #f2763741;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__UoQGF",
	"focus": "styles_focus__-CD95"
};
export default ___CSS_LOADER_EXPORT___;
