import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './configureStore.js'

import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { authKeys } from '../src/utils/config'

ReactDOM.render(
  <Auth0Provider
    domain={authKeys.domain}
    clientId={authKeys.clientId}
    scope={authKeys.scope}
    audience={authKeys.audience}
    redirectUri={window.location.origin}
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
)

window.store = store

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
