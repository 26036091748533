import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import cn from 'classnames'
import styles from './styles.module.scss'

const propTypes = {
  onClick: PropTypes.func,
}

const defaultProps = {
  onClick: noop,
}

const SkipButton = ({ onClick, className }) => (
  <div className={cn(styles.skipButton, className)} onClick={onClick}>
    Skip
  </div>
)

SkipButton.propTypes = propTypes
SkipButton.defaultProps = defaultProps

export default SkipButton
