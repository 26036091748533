import { useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import cn from 'classnames'

const NavigationButton = ({
  beforeRedirect,
  to,
  className,
  disabled,
  ...props
}) => {
  const history = useHistory()

  const handleClick = () => {
    beforeRedirect && beforeRedirect()
    if (to)
      history.push({
        pathname: to,
        state: { from: history.location.pathname },
      })
  }

  return (
    <div
      className={cn({ disabled }, styles.container, className)}
      onClick={handleClick}
      {...props}
    />
  )
}

export default NavigationButton
