import React from 'react'
import cn from 'classnames'
import keepReadingButtonImage from 'assets/KeepReadingButton.png'
import NavigationButton from 'components/NavigationButton'
import styles from './styles.module.scss'

const KeepReadingButton = ({ beforeRedirect, className }) => {
  return (
    <NavigationButton
      className={cn(styles.container, className)}
      beforeRedirect={beforeRedirect}
      to="/pick-level"
    >
      <img src={keepReadingButtonImage} alt="continue" />
    </NavigationButton>
  )
}

export default KeepReadingButton
