import { useState, useEffect } from 'react'
import cn from 'classnames'
import { getRectCenterPos } from 'utils'
import Triangle from './triangle.svg'
import ReadAloudButton from 'components/ReadAloudButton'
import { SoundBySoundColors } from 'config'
import PageText from 'components/Reader/PageText'
import styles from './styles.module.scss'
import { escapePathForURL } from 'globalMethods'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const WordHintConfig = {
  WordPattern: { height: 210, padding: 20 },
  SoundBySound: { height: 120, padding: 20 },
  ReadAloud: { height: 130, padding: 20 },
  SeenBefore: { height: 350, padding: 20 },
}

const LEFT = 1
const RIGHT = 2

const WordHint = ({ children, dimensions, wordBounds, wordHint, bookInfo, wordRefs }) => {
  const [seconds, setSeconds] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [scaffold, setScaffold] = useState(null)
  const [containerStyles, setContainerStyles] = useState({})
  const [direction, setDirection] = useState(true)
  const renderWordHint = {}

  renderWordHint.WordPattern = ({ pdfDoc }) => {
    return (
      <Document file={pdfDoc} renderMode="svg">
        <Page
          pageNumber={1}
          height={containerStyles.height - containerStyles.padding - 5}
        />
      </Document>
    )
  }

  renderWordHint.SoundBySound = ({ phonemes }) => {
    return (
      <div className={cn(styles.soundBySoundContainer)}>
        {phonemes.map((w, i) => (
          <div key={i} className={cn({ [styles.active]: seconds === i })}>
            <span
              style={{
                color: SoundBySoundColors[i % SoundBySoundColors.length],
              }}
              className={styles.soundBySoundPhoneme}
            >
              {w.toLowerCase()}
            </span>
          </div>
        ))}
      </div>
    )
  }

  renderWordHint.ReadAloud = ({ audio }) => {
    return (
      <div className={styles.wordHintImageContainer}>
        <ReadAloudButton
          className={styles.readAloudWordHintImage}
          src={bookInfo.requestMediaFile(audio)}
        />
      </div>
    )
  }

  renderWordHint.SeenBefore = ({ image, pageItem, word }) => {
    return image ? (

      <div
        className={cn(
          styles.wordHintImageContainer,
          styles.seenBeforeImageContainer
        )}
      >
        <img src={bookInfo.requestMediaFile(escapePathForURL(image))} alt="" />
      </div>

    ) : (

      <div>
        <div style={{height:225, width:'100%'}}>
          <img style={{height:225, width:'100%'}} src={pageItem.backgroundImageSrc} alt="" />
        </div>
        <PageText
          className={styles.seenBeforePageText}
          words={pageItem.words}
          wordIndex={word - 1}
          bookInfo={bookInfo}
          wordRefs={wordRefs}
          wordHint={"Seen Before"}
        />
      </div>

    )
  }

  const resetTimer = () => {
    setSeconds(0)
    setIsActive(true)
  }

  useEffect(() => {
    let interval = null
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((s) => s + 1)
      }, 1300)
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, seconds])

  useEffect(() => {
    const [data] = Object.entries(wordHint)
    if (!data) {
      return setScaffold(null)
    }
    const [scaffoldType, scaffoldData] = data
    const { height, padding } = WordHintConfig[scaffoldType]
    const scale = dimensions.width <= 600 ? 0.4 : 1
    const scaledHeight = height * scale
    const scaledPadding = padding * scale
    const wordCenterPos = getRectCenterPos(wordBounds)
    const transforms = [
      `translateY(${wordBounds.y - wordBounds.height - scaledHeight - 10}px)`,
    ]
    transforms.push(`translateX(${wordCenterPos.x - scaledPadding - 10}px)`)
    setContainerStyles({
      height: scaledHeight,
      padding: scaledPadding,
      transform: transforms.join(' '),
    })
    setScaffold({
      scaffoldType,
      scaffoldData,
    })
    setDirection(wordCenterPos.x > dimensions.width / 2 ? RIGHT : LEFT)
    resetTimer()
  }, [dimensions, wordHint, wordBounds])

  if (!scaffold) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 20,
        left: 0,
        // zIndex: 999,
        display: 'flex',
        justifyContent:'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: `${containerStyles.height}px`,
        margin: 10,
        transform: containerStyles.transform,
      }}
    >
      <div
        className={styles.wordHintContainer}
        style={{
          padding: `${containerStyles.padding}px`,
          marginLeft: direction === RIGHT && '-80%',
          backgroundColor: '#fff',
          height: '100%',
          boxShadow:
            '0 0 #0000, 0 0 #0000, 0 1px 6px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',
        }}
      >
        {renderWordHint[scaffold.scaffoldType](scaffold.scaffoldData)}
      </div>
      {direction && (
        <img
          alt=""
          src={Triangle}
          style={{
            zIndex: 9999,
            paddingLeft: direction === LEFT && `${containerStyles.padding}px`,
            paddingRight: direction === RIGHT && `${containerStyles.padding}px`,
            height: '1.3em',
            marginTop: '-0.04em',
          }}
        />
      )}
    </div>
  )
}

export default WordHint
