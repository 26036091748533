import styles from './styles.module.scss'
import { totmContentBaseUrl } from 'config'
import SelectionCardGrid, {
  SelectionCardGridItem,
} from 'components/SelectionCardGrid'
import { useHistory } from 'react-router-dom'
import { useReaderProvider } from 'context/ReaderContext'
import { useSelector } from 'react-redux'

const SelectReadabilityLevelPage = () => {
  const history = useHistory()
  const themes = useSelector((s) => s.classroom.themes)
  console.log("themes", themes)
  const { reading } = useReaderProvider()
  return (
    <SelectionCardGrid showBackButton={!reading}>
      {Object.values(themes).map(({ enabled, name, thumbnail }) => (
        <SelectionCardGridItem
          key={name}
          className={styles.gridItem}
          coverImage={`${totmContentBaseUrl}/${thumbnail}`}
          disabled={!enabled}
          onClick={() => history.push(`/levels/${name}`)}
        />
      ))}
    </SelectionCardGrid>
  )
}

export default SelectReadabilityLevelPage
