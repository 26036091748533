// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_w-100__gFnud {\n  width: 100%;\n}\n\n.styles_loginChoice__2SR2- {\n  cursor: pointer;\n  width: 100%;\n  height: 40px;\n  border-bottom: 1px solid #e3e3e3;\n  padding: 1.5em 1em 2.5em 1em;\n}\n\n.styles_loginButton__Rwsve {\n  cursor: pointer;\n  background-color: #ffffff;\n  text-align: center;\n  border: 0.25em solid #f48b30;\n  color: #f48b30;\n  border-radius: 0.75em;\n  font-size: 2.5vw;\n  width: 30rem;\n  max-width: 50vw;\n  padding: 0.65em;\n}\n\n.styles_loginButton__Rwsve:hover {\n  background-color: #f48b30;\n  color: white;\n}", "",{"version":3,"sources":["webpack://./src/styles/totm/_variables.scss","webpack://./src/pages/LoginPage/styles.module.scss"],"names":[],"mappings":"AAaA;EACE,WAAA;ACZF;;AACA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;EACA,4BAAA;AAEF;;AACA;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,4BAAA;EACA,cAAA;EACA,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;AAEF;;AACA;EACE,yBAAA;EACA,YAAA;AAEF","sourcesContent":["$blue: #3dc0d0;\n$blue-link: #62a3e8;\n$purple: #8B6CA2;\n$background-purple: #323C5A;\n$dark-purple: #7854b9;\n$purple2: #8967a0;\n$white: #fff;\n$orange: #ff9900;\n\n$gray-concord: #7e7b7e;\n\n$card-shadow: rgba(0, 0, 0, 0.2);\n\n.w-100 {\n  width: 100%;\n}\n","@import 'styles/totm/_mixins';\n@import 'styles/totm/_variables';\n\n.loginChoice {\n  cursor: pointer;\n  width: 100%;\n  height: 40px;\n  border-bottom: 1px solid #e3e3e3;\n  padding: 1.5em 1em 2.5em 1em;\n}\n\n.loginButton {\n  cursor: pointer;\n  background-color: #ffffff;\n  text-align: center;\n  border: 0.25em solid #f48b30;\n  color: #f48b30;\n  border-radius: 0.75em;\n  font-size: 2.5vw;\n  width: 30rem;\n  max-width: 50vw;\n  padding: 0.65em;\n}\n\n.loginButton:hover {\n  background-color: #f48b30;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"w-100": "styles_w-100__gFnud",
	"loginChoice": "styles_loginChoice__2SR2-",
	"loginButton": "styles_loginButton__Rwsve"
};
export default ___CSS_LOADER_EXPORT___;
