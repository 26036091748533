import cn from 'classnames'
import styles from './styles.module.scss'
import BackButton from 'components/BackButton'
import { useHistory } from 'react-router-dom'
import { useReaderProvider } from 'context/ReaderContext'

const SelectionCardGrid = ({ className, showBackButton = true, ...props }) => {
  const history = useHistory()
  const { reading } = useReaderProvider()

  const goBack = () => {
    history.goBack()
  }


  return (
    <div className={cn('centered-card-layout', className)}>
      <div className={cn(styles.selectionCard, 'card')}>
        <div className={styles.selectionCardHeader}>
          {showBackButton ? (
            <BackButton onClick={goBack} className={styles.backButton} />
          ) : (
            <br />
          )}
        </div>
        <div className={cn(styles.selectionCardBody)} alt="" {...props} />
      </div>
    </div>
  )
}

export { default as SelectionCardGridItem } from './SelectionCardGridItem'
export default SelectionCardGrid
