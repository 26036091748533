import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'

const LogoutButton = ({ className }) => {
  const { logout } = useAuth0()
  const { pathname } = useLocation()

  const doLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  if (pathname.includes('/reader')) {
    return null
  }

  return (
    <div className={className}>
      <FontAwesomeIcon
        icon="door-open"
        style={{ cursor: 'pointer' }}
        onClick={doLogout}
      />
    </div>
  )
}

export default LogoutButton
