import { useReaderProvider } from 'context/ReaderContext'
import BackButton from 'components/BackButton'
import ButtonsCardLayout from 'components/ButtonsCardLayout'
import avatarPlaceholderImage from 'assets/avatar-placeholder.png'
import checkSquare from 'assets/checksquare.png'
import cn from 'classnames'
import pickABookImageSrc from 'assets/pick-a-book.png'
import styles from './styles.module.scss'

const GettingStartedPage = ({ history }) => {
  const { readers, setCurrentSelectedChildIndex } = useReaderProvider()

  const readerDisplayName = (reader, index) => {
    return reader.display_name || `Child ${index + 1}`
  }

  const allReadersSelected = () => {
    return !readers.find((o) => !o.ID)
  }

  const goBack = () => {
    history.push('/')
  }

  return (
    <ButtonsCardLayout>
      <ButtonsCardLayout.Header>
        <BackButton onClick={goBack} className={styles.backButton} />
        <div className={styles.headerTitleContainer}>
          <div className={styles.headerTitleText}>Getting started</div>
        </div>
      </ButtonsCardLayout.Header>
      <ButtonsCardLayout.Body>
        {readers.map((reader, index) => (
          <ButtonsCardLayout.Button
            beforeRedirect={() => {
              setCurrentSelectedChildIndex(index)
            }}
            key={index}
            contentLeft={
              reader?.ID && (
                <img
                  src={reader?.picture || avatarPlaceholderImage}
                  className={styles.readerThumbNail}
                  alt=""
                />
              )
            }
            contentCenter={readerDisplayName(reader, index)}
            contentRight={reader?.ID && <img src={checkSquare} alt="check" />}
            className={cn('blue text-white', styles.cardButton)}
            to="/select-children"
          />
        ))}
        <ButtonsCardLayout.Button
          disabled={!allReadersSelected()}
          contentCenter="Pick a Book"
          contentRight={<img src={pickABookImageSrc} alt="" />}
          className={cn(styles.ctaButton, 'purple', 'text-white')}
          to="/pick-level"
        />
      </ButtonsCardLayout.Body>
    </ButtonsCardLayout>
  )
}
export default GettingStartedPage
