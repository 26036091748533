// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_footerText__oQXrQ {\n  margin-top: 60px;\n  text-align: center;\n  font-size: 14px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ModeSelectionPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AACF","sourcesContent":[".footerText {\n  margin-top: 60px;\n  text-align: center;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerText": "styles_footerText__oQXrQ"
};
export default ___CSS_LOADER_EXPORT___;
