import cn from 'classnames'
import styles from './styles.module.scss'

const SelectionCardGridItem = ({
  className,
  style,
  coverImage,
  disabled,
  onClick,
}) => {
  return (
    <div className={cn(styles.selectionCardGridItemContainer, className)}>
      <div
        onClick={disabled ? null : onClick}
        className={cn(styles.selectionCardGridItem, {
          [styles.disabled]: disabled,
        })}
      >
        <img src={coverImage} alt="" />
      </div>
    </div>
  )
}
export default SelectionCardGridItem
