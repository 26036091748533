import AudioRecordButton from 'components/AudioRecordButton'
import badgerRecordTipImage from 'assets/badger_record_tip.png'
import SkipButton from 'components/SkipButton'
import Slider from '../Slider'
import { setWordIndex } from 'actions/book'
import { useDispatch } from 'react-redux'
import styles from './styles.module.scss'

const DecodingCheck = ({ words, handleSubmit, readerIndex, pageStep, PAGE_STEP }) => {
  const dispatch = useDispatch()

  const onEndRecording = (mediaBlobUrl) => {
    setTimeout(() => handleSubmit(mediaBlobUrl), 2000)
  }

  return (
    <div className={styles.wrapper}>
      <SkipButton className={styles.skipButton} onClick={handleSubmit} />
      <div className={styles.audioRecordButtonContainer}>
        <div className={styles.badgerRecordTipContainer}>
          <div className={styles.badgerRecordTipImageContainer}>
            <img src={badgerRecordTipImage} alt="" />
          </div>
          <div className={styles.badgerRecordTipTextContainer}>
            <p>Tap to START and then</p>
            <p>tap to STOP and close book</p>
          </div>
        </div>
        <AudioRecordButton
          className={styles.audioRecordButton}
          onEndRecording={onEndRecording}
        />
      </div>
      <div className={styles.comprehensionTextContainer}>
        <Slider
          pageStep={pageStep}
          PAGE_STEP={PAGE_STEP}
          words={words.map((v) => v.text)}
          wordIndex={0}
          onChangeWord={(i) => dispatch(setWordIndex(i))}
          readerIndex={readerIndex}
        />
      </div>
    </div>
  )
}

export default DecodingCheck
