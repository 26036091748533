import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'

export const PrivateRoute = ({ component, ...rest }) => {
  // since you can't use a lowercase var to instantiate a component in JSX
  // we re-assign the var ref to an uppercase
  const Component = component

  const { isAuthenticated } = useAuth0()

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
