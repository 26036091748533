import AudioPlayer from 'react-audio-player'
import { useRef } from 'react'

function AudioPlayerButton({
  src,
  className,
  style,
  onEnded,
  onClick,
  onPlay,
  ...props
}) {
  const ref = useRef(null)

  const play = () => {
    const audio = ref.current?.audioEl?.current
    if (audio && audio.paused) {
      audio.play()
    }
  }

  return (
    <div className={className} style={style} onClick={onClick || play}>
      <AudioPlayer
        ref={ref}
        src={src}
        autoPlay={false}
        controls={false}
        onEnded={onEnded}
        onPlay={onPlay}
      />
      {props.children}
    </div>
  )
}

export default AudioPlayerButton
