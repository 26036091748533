export const types = {
  SET_STUDENTS: 'classroom/SET_STUDENTS',
  SET_TEACHER: 'classroom/SET_TEACHER',
  SET_THEMES: 'classroom/SET_THEMES',
  CLEAR: 'classroom/CLEAR',
}

export const setStudents = (payload) => ({
  type: types.SET_STUDENTS,
  payload,
})

export const setTeacher = (payload) => ({
  type: types.SET_TEACHER,
  payload,
})

export const setThemes = (payload) => ({
  type: types.SET_THEMES,
  payload,
})

export const clear = () => ({
  type: types.CLEAR,
})
