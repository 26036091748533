// uses https://video-react.js.org/
import { useState, useRef } from 'react'
import cn from 'classnames'
import ReadAloudButton from 'components/ReadAloudButton'
import { BigPlayButton, ControlBar, Player } from 'video-react'
import SkipButton from 'components/SkipButton'
import styles from './styles.module.scss'

const Stage = ({
  className,
  coverImage,
  coverAudio,
  preReadingVideo,
  onEnded,
}) => {
  const playerRef = useRef()
  const [started, setStarted] = useState(false)

  return (
    <div className={cn(className, styles.stage)}>
      {started && 
      <div className={styles.header}>
        <SkipButton onClick={onEnded} />
      </div>
      }
      <div className={styles.playerOverlay} />
      <Player
        ref={playerRef}
        poster={coverImage}
        onEnded={onEnded}
        onPlay={() => setStarted(true)}
      >
        <source src={preReadingVideo} />
        <BigPlayButton disabled />
        <ControlBar disabled></ControlBar>
      </Player>
      {!started ? (
        <ReadAloudButton
          onEnded={() => {
            playerRef.current.play()
          }}
          className={styles.readAloudCoverButton}
          src={coverAudio}
        />
      ) : null}
    </div>
  )
}

export default Stage
