// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_headerTitleContainer__GHImP {\n  -webkit-user-select: none;\n          user-select: none;\n  font-size: 38px;\n  display: flex;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n}\n@media (max-width: 600px) {\n  .styles_headerTitleContainer__GHImP {\n    font-size: 20px;\n  }\n}\n\n.styles_backButton__Oo-K4 {\n  width: 50px;\n  z-index: 1;\n}\n@media (max-width: 600px) {\n  .styles_backButton__Oo-K4 {\n    width: 36px;\n  }\n}\n\n.styles_readerThumbNail__rHTkO {\n  border-radius: 50%;\n}\n\n.styles_headerTitleText__gXNB9 {\n  line-height: 38px;\n  font-weight: 500;\n}\n\n.styles_ctaButton__IS4wi {\n  margin: 18px 0;\n}", "",{"version":3,"sources":["webpack://./src/pages/GettingStartedPage/styles.module.scss","webpack://./src/styles/totm/_mixins.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;UAAA,iBAAA;EACA,eAAA;EAIA,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;AAJF;ACLI;EDAJ;IAII,eAAA;EAKF;AACF;;AAEA;EACE,WAAA;EAIA,UAAA;AAFF;ACfI;EDYJ;IAGI,WAAA;EAIF;AACF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,iBAAA;EACA,gBAAA;AAGF;;AAAA;EACE,cAAA;AAGF","sourcesContent":["@import 'styles/totm/_mixins';\n\n.headerTitleContainer {\n  user-select: none;\n  font-size: 38px;\n  @include respond-to(xs) {\n    font-size: 20px;\n  }\n  display: flex;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n}\n\n.backButton {\n  width: 50px;\n  @include respond-to(xs) {\n    width: 36px;\n  }\n  z-index: 1;\n}\n\n.readerThumbNail {\n  border-radius: 50%;\n}\n\n.headerTitleText {\n  line-height: 38px;\n  font-weight: 500;\n}\n\n.ctaButton {\n  margin: 18px 0;\n}\n","@mixin respond-to($media) {\n  @if $media == xs {\n    @media (max-width: 600px) {\n      @content;\n    }\n  } @else if $media == sm {\n    @media (max-width: 768px) {\n      @content;\n    }\n  } @else if $media == lm {\n    @media (max-width: 1280px) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerTitleContainer": "styles_headerTitleContainer__GHImP",
	"backButton": "styles_backButton__Oo-K4",
	"readerThumbNail": "styles_readerThumbNail__rHTkO",
	"headerTitleText": "styles_headerTitleText__gXNB9",
	"ctaButton": "styles_ctaButton__IS4wi"
};
export default ___CSS_LOADER_EXPORT___;
