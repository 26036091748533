import { createRef, useEffect, useRef, useState, useMemo } from 'react'
import { getRectCenterPos } from 'utils'
import WordHint from './WordHint'
import { connect } from 'react-redux'
import cn from 'classnames'
import { DraggableCore as Draggable } from 'react-draggable'
import { useDimensions } from 'context/Dimensions'
import PageText from './PageText'
import slider from 'assets/slider.png'
import slider2 from 'assets/slider2.png'
import styles from './styles.module.scss'

function Slider({
  bookInfo,
  words,
  hidden,
  pageItems,
  disabled,
  currentPageIndex,
  currentWordIndex,
  toolbox,
  onChangeWord,
  color,
  pageStep,
  PAGE_STEP
}) {
  const dimensions = useDimensions()
  const handleRef = useRef(null)
  const wordRefs = useMemo(() => words.map(createRef), [words])
  const [wordBounds, setWordBounds] = useState([])
  const [wordHint, setWordHint] = useState({})
  const [sliderOriginX, setSliderOriginX] = useState(0)
  const [sliderCenterOffset, setSliderCenterOffset] = useState(0)

  const findWordIndex = (sliderCenterX) => {
    return wordBounds.findIndex((wordRect) => {
      return sliderCenterX + sliderCenterOffset < wordRect.x + wordRect.width
    })
  }

  const handleSliderXChange = (centerX) => {
    const i = findWordIndex(centerX)
    if (i !== -1 && i !== currentWordIndex) {
      setWordHint({})
      onChangeWord(i)
    }
  }

  const getElementBoundingClientRect = (ref) => {
    return ref.current?.getBoundingClientRect() || {}
  }

  useEffect(() => {
    const { scaffoldData, scaffoldType } = toolbox || {}
    if (!scaffoldData) {
      setWordHint({})
      return
    }
    switch (scaffoldType) {
      case 'WordPattern':
        return setWordHint({
          [scaffoldType]: {
            ...scaffoldData,
            pdfDoc: bookInfo.requestMediaFile(scaffoldData.pattern),
          },
        })
      case 'SeenBefore':
        return setWordHint({
          [scaffoldType]: {
            ...scaffoldData,
            wordIndex: scaffoldData.word - 1,
            pageItem: pageItems[scaffoldData.page - 1],
          },
        })
      default:
        return setWordHint({
          [scaffoldType]: { ...scaffoldData },
        })
    }
  }, [toolbox, bookInfo, pageItems])

  const reset = (wordIndex = currentWordIndex) => {
    const wordRects = wordRefs.map(getElementBoundingClientRect)
    setWordBounds(wordRects)
    const sliderRect = getElementBoundingClientRect(handleRef)
    const currentWordRect = wordRects[wordIndex] || wordRects[0]
    const centerOffset = sliderRect.width / 2
    const originX = getRectCenterPos(currentWordRect).x - centerOffset
    setSliderCenterOffset(centerOffset)
    setSliderOriginX(originX)
    handleRef.current.style.position = 'absolute'
    handleRef.current.style.left = `${originX}px`
    handleRef.current.style.transform = null
  }

  useEffect(reset, [wordRefs, dimensions])

  useEffect(() => {
    setWordHint({})
    onChangeWord(0)
    reset(0)
  }, [currentPageIndex])

  return (
    <div>
      { ![PAGE_STEP.READ_ALOUD].includes(pageStep) && 
        <WordHint
          bookInfo={bookInfo}
          wordHint={wordHint}
          wordBounds={wordBounds[currentWordIndex]}
          dimensions={dimensions}
          wordIndex={currentWordIndex}
          wordRefs={wordRefs}
        />
      }
      <PageText
        words={words}
        bookInfo={bookInfo}
        wordIndex={currentWordIndex}
        wordRefs={wordRefs}
        wordMargin="20px"
      />
      <Draggable
        key={currentPageIndex}
        axis="x"
        disabled={disabled}
        onStop={(e, d) => {
          const sliderRect = getElementBoundingClientRect(handleRef)
          const sliderCenterX = getRectCenterPos(sliderRect).x
          const wordCenterX = getRectCenterPos(wordBounds[currentWordIndex]).x
          const offsetX = wordCenterX - sliderCenterX
          handleRef.current.style.transform += ` translateX(${offsetX}px)`
        }}
        onDrag={(e, d) => {
          const sliderCenterX = d.x - sliderCenterOffset
          const translateX = sliderCenterX - sliderOriginX
          handleRef.current.style.transform = `translateX(${translateX}px)`
          handleSliderXChange(sliderCenterX)
        }}
      >
        <img
          src={color === 'secondary' ? slider2 : slider}
          ref={handleRef}
          alt="slider"
          className={cn('handle', styles.slider, {
            [styles.hidden]: hidden,
          })}
        />
      </Draggable>
    </div>
  )
}

export default connect((state) => ({ ...state.book }))(Slider)
