import React, { useCallback, useContext, useEffect, useState } from 'react'
import { readingModes } from 'config'
import threebadgers from 'assets/threebadgers.png'
import { useHistory } from 'react-router-dom'

const ReaderContext = React.createContext()

export const ReaderProvider = ({ children }) => {
  const history = useHistory()
  const [currentMode, setCurrentMode] = useState(null)
  const [currentSelectedChildIndex, setCurrentSelectedChildIndex] = useState(0)
  const [reading, setReading] = useState(false)

  const [readers, setReaders] = useState([])
  const [mode, setMode] = useState('')

  const setBuddyMode = useCallback(() => {
    setCurrentMode(readingModes.BUDDY_MODE)
    setReaders([{}, {}])
  }, [setCurrentMode])

  const setIndividualMode = useCallback(() => {
    setCurrentMode(readingModes.INDIVIDUAL_MODE)
    setReaders([{}])
  }, [setCurrentMode])

  const setGroupMode = useCallback(() => {
    setCurrentMode(readingModes.GROUP_MODE)
    setReaders([{ ID: 'GROUP', picture: threebadgers }])
  }, [setCurrentMode])

  const setCurrentReaderAvatar = (avatarImageSrc) => {
    setReaders(([...state]) => {
      state[currentSelectedChildIndex] = {
        ...state[currentSelectedChildIndex],
        picture: avatarImageSrc,
      }
      return state
    })
  }

  const setReaderData = useCallback(
    (index, readerData) => {
      setReaders(([...state]) => {
        state[index] = { ...readerData }
        return state
      })
    },
    [setReaders]
  )

  useEffect(() => {
    if (!currentMode) {
      history.replace('/')
    }
  }, [currentMode, history])

  return (
    <ReaderContext.Provider
      value={{
        currentSelectedChildIndex,
        reading,
        readers,
        setCurrentReaderAvatar,
        setBuddyMode,
        setCurrentSelectedChildIndex,
        currentMode,
        setIndividualMode,
        setReading,
        setGroupMode,
        setReaderData,
        mode,
        setMode
      }}
    >
      {children}
    </ReaderContext.Provider>
  )
}

export const useReaderProvider = () => useContext(ReaderContext)

export default React.memo(ReaderContext)
