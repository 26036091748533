import cn from 'classnames'
import Button from './Button'
import styles from './styles.module.scss'

const ButtonsCardLayout = ({ animating, className, ...props }) => (
  <div className={cn(styles.scene, 'centered-card-layout', className)}>
    <div
      className={cn(
        [{ [styles.animateOut]: animating }],
        styles.cardContainer,
        'buttons-card'
      )}
      {...props}
    />
  </div>
)

ButtonsCardLayout.Body = (props) => (
  <div className="buttons-card-body" {...props} />
)

ButtonsCardLayout.Header = (props) => (
  <div className="buttons-card-header" {...props} />
)

ButtonsCardLayout.Button = Button

export default ButtonsCardLayout
