// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__jt6v5 {\n  cursor: pointer;\n}\n.styles_container__jt6v5 img {\n  height: 100%;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/KeepReadingButton/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ","sourcesContent":[".container {\n  cursor: pointer;\n  img {\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__jt6v5"
};
export default ___CSS_LOADER_EXPORT___;
