import { combineReducers, createStore, applyMiddleware } from 'redux'
import loggerMiddleware from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import bookReducer from 'reducers/book'
import classroomReducer from 'reducers/classroom'
import directoryReducer from 'reducers/directory'

const rootReducer = combineReducers({
  directory:directoryReducer,
  classroom: classroomReducer,
  book: bookReducer,
})

const middleware = [thunkMiddleware]

if (process.env.REACT_APP_NODE_ENV !== 'production') {
  middleware.push(loggerMiddleware)
}

export const store = createStore(rootReducer, applyMiddleware(...middleware))
