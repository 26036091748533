import React from 'react'
import { useEffect } from 'react'
import recordButtonImage from 'assets/RecordButton.png'
import recordButtonDoneImage from 'assets/RecordButton_DONE.png'
import recordButtonActiveImage from 'assets/RecordButton_RECORDING.png'
import cn from 'classnames'
import styles from './styles.module.scss'
import { useReactMediaRecorder } from 'react-media-recorder'
import { MIC_REQUIRED_TITLE } from 'utils/defaults'
import AudioPlayer from 'react-audio-player'
import successAudio from 'assets/Correct.wav'
import { useRef } from 'react'

const recordButtonImages = {
  recording: recordButtonActiveImage,
  stopped: recordButtonDoneImage,
  DEFAULT: recordButtonImage,
}

const AudioRecordButton = ({
  className,
  onEndRecording = () => {},
  onStartRecording = () => {},
  ...props
}) => {
  const {
    error,
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl
  } = useReactMediaRecorder({ audio: true })

  const audioPlayerRef = useRef(null)

  const isPermissionDenied = error === 'permission_denied'
  const isRecording = status === 'recording'

  const handlePermissionDenied = () => {
    alert(MIC_REQUIRED_TITLE)
  }

  React.useEffect(() => {
    if (mediaBlobUrl) {
      onEndRecording(mediaBlobUrl)
    }
  }, [mediaBlobUrl])

  useEffect(() => {
    if (isPermissionDenied) {
      handlePermissionDenied()
    }
  }, [error, isPermissionDenied])

  const playSuccessAudio = () => {
    const audio = audioPlayerRef.current?.audioEl?.current
    if (audio && audio.paused) {
      audio.play()
    }
  }

  const handleClick = () => {
    if (isPermissionDenied) {
      return handlePermissionDenied()
    }
    if (!isRecording) {
      onStartRecording()
      startRecording()
    } else {
      stopRecording()
      playSuccessAudio()
    }
  }

  return (
    <>
      <div onClick={handleClick}>
        <img
          className={cn(className, styles.button)}
          src={recordButtonImages[status] || recordButtonImages.DEFAULT}
          alt="record"
        />
        <AudioPlayer
          ref={audioPlayerRef}
          src={successAudio}
          autoPlay={false}
          controls={false}
          volume={0.05}
        />
      </div>
      <div className={styles.stopRecording}>
        {status === 'recording' ? `■ Stop Recording` : <br />}
      </div>
    </>
  )
}

export default AudioRecordButton
