// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_category__u4mdl img {\n  width: 170px !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/CategorySelectionPage/styles.module.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;AAAJ","sourcesContent":[".category {\n  img {\n    width: 170px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category": "styles_category__u4mdl"
};
export default ___CSS_LOADER_EXPORT___;
