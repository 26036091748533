import SelectionCardGrid, {
  SelectionCardGridItem,
} from 'components/SelectionCardGrid'
import { useHistory } from 'react-router-dom'
import { useReaderProvider } from 'context/ReaderContext'
import ParrotAvatar from 'assets/avatars/parrot.png'
import ToucanAvatar from 'assets/avatars/toucan.png'
import BunnyAvatar from 'assets/avatars/bunny.png'
import HowlerAvatar from 'assets/avatars/howler monkey.png'
import LionAvatar from 'assets/avatars/lion cub.png'
import MouseAvatar from 'assets/avatars/mouse.png'
import OwlAvatar from 'assets/avatars/owl.png'
import PiranhaAvatar from 'assets/avatars/piranha.png'
import SlothAvatar from 'assets/avatars/sloth.png'
import FrogAvatar from 'assets/avatars/frog.png'

function SelectAvatar() {
  const history = useHistory()
  const { readers, setCurrentReaderAvatar } = useReaderProvider()

  const handleClick = (avatarImageSrc) => {
    setCurrentReaderAvatar(avatarImageSrc)
    history.push('/get-started')
  }

  const canSelectAvatar = (avatarImgSrc) => {
    return !readers.find((o) => o.picture === avatarImgSrc)
  }

  return (
    <SelectionCardGrid>
      {[
        ParrotAvatar,
        ToucanAvatar,
        BunnyAvatar,
        HowlerAvatar,
        LionAvatar,
        MouseAvatar,
        OwlAvatar,
        PiranhaAvatar,
        SlothAvatar,
        FrogAvatar,
      ]
        .filter(canSelectAvatar)
        .map((src) => (
          <SelectionCardGridItem
            key={src}
            onClick={() => {
              handleClick(src)
            }}
            coverImage={src}
          />
        ))}
    </SelectionCardGrid>
  )
}

export default SelectAvatar
