import React from 'react'
import debounce from 'lodash.debounce'

const DimensionsContext = React.createContext()

export const DimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  React.useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 250)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <DimensionsContext.Provider value={dimensions}>
      {children}
    </DimensionsContext.Provider>
  )
}

export const useDimensions = () => React.useContext(DimensionsContext)

export default DimensionsContext
