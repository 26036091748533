import readAloudImageSrc from 'assets/read-aloud.png'
import AudioPlayerButton from 'components/AudioPlayerButton'
import cn from 'classnames'
import styles from './styles.module.scss'

const ReadAloudButton = ({ className, ...props }) => (
  <AudioPlayerButton {...props}>
    <img
      className={cn(className, styles.button)}
      src={readAloudImageSrc}
      alt="ReadAloud"
    />
  </AudioPlayerButton>
)

export default ReadAloudButton
