import { QueryClient, QueryClientProvider } from 'react-query'
import Routes from './Routes'
import { useAuth0 } from '@auth0/auth0-react'
import initFontAwesome from './utils/initFontAwesome'
import LoadingOverlay from 'components/LoadingOverlay'
import LogoutButton from 'components/LogoutButton'
import { ReaderProvider } from 'context/ReaderContext'
import { DimensionsProvider } from 'context/Dimensions'
import { useHistory } from 'react-router-dom'
import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import backgroundImage from 'assets/totm-background.png'
import { useEffect, useState } from 'react'

const queryClient = new QueryClient()
initFontAwesome()

function App() {
  // Comment:  not able to use a closed box in the login page because the image is not similar to
  // backround.png
  const { isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()

  window.addEventListener('beforeunload', (e) => {
    ;(e || window.event).returnValue = ''
    return (e || window.event).returnValue
  })

  const [ hideLogo, setHideLogo ] = useState(false)

  useEffect(()=>{
    history.listen((location) => {
      if(location.pathname.includes('reader')){
        setHideLogo(true)
      }else{
        setHideLogo(false)
      }
    })
  }, [history])

  return (
    <QueryClientProvider client={queryClient}>
      {!hideLogo && (
        <div style={{position:'absolute', left:0, top:-30}}><img height={200} width={200} src={"https://cdn.toolsofthemind.org/ToolsLogoFullAnimatedWhite.svg"}/></div>
      )}
      {isAuthenticated && <LogoutButton className="logout-btn mr-4" />}
      <ReaderProvider>
        <DimensionsProvider>
          <div
            className="App bg-purple"
            style={{ backgroundImage: `url(${backgroundImage})`}}
          >
            <LoadingOverlay loading={isLoading}>
              <Routes />
            </LoadingOverlay>
            {!hideLogo && (
            <div className="text-white" style={{position:'absolute', bottom:10, width:'100%', textAlign:'center'}}>PowerTools &#169; 2023 Tools of the Mind. All rights reserved.</div>
            )}
            </div>
        </DimensionsProvider>
      </ReaderProvider>
    </QueryClientProvider>
  )
}

export default App
