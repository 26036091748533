import { Switch, Route } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import BookSelectionPage from 'pages/BookSelectionPage'
import CategorySelectionPage from 'pages/CategorySelectionPage'
import GettingStartedPage from 'pages/GettingStartedPage'
import LoginPage from 'pages/LoginPage'
import ModeSelectionPage from 'pages/ModeSelectionPage'
import SelectAvatar from 'pages/SelectAvatar'
import ReaderPage from 'pages/ReaderPage'
import SelectChildrenPage from 'pages/SelectChildren'
import SelectReadabilityLevelPage from 'pages/SelectReadabilityLevelPage'

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={ModeSelectionPage} />
    <PrivateRoute
      exact
      path="/select-children"
      component={SelectChildrenPage}
    />
    <PrivateRoute exact path="/select-avatar" component={SelectAvatar} />

    <PrivateRoute exact path="/get-started" component={GettingStartedPage} />
    <PrivateRoute
      exact
      path="/pick-level"
      component={SelectReadabilityLevelPage}
    />
    <PrivateRoute
      exact
      path="/levels/:readability"
      component={CategorySelectionPage}
    />
    <PrivateRoute
      exact
      path="/levels/:readability/category/:category"
      component={BookSelectionPage}
    />
    <PrivateRoute
      exact
      path="/levels/:readability/category/:category/reader/:bookId"
      component={ReaderPage}
    />
    <Route path="/login" component={LoginPage} />
  </Switch>
)

export default Routes
