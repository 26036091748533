import Reader from 'components/Reader'
import LoadingOverlay from 'components/LoadingOverlay'
import { useEffect, useState } from 'react'
import { useReaderProvider } from 'context/ReaderContext'
import BookInfoHelper from 'helpers/BookInfoHelper'
import { useParams } from 'react-router-dom'

function ReaderPage() {
  const { setReading, readers, currentMode } = useReaderProvider()
  const params = useParams()
  const [bookInfo, setBookInfo] = useState(null)

  useEffect(() => {
    setReading(true)
    new BookInfoHelper({ ...params, readers }).init().then(setBookInfo)
  }, [params, readers])

  return (
    <LoadingOverlay loading={!bookInfo}>
      <Reader
        {...params}
        readers={readers}
        mode={currentMode}
        bookInfo={bookInfo}
      />
    </LoadingOverlay>
  )
}

export default ReaderPage
