import React from 'react'
import cn from 'classnames'
import homeButtonImage from 'assets/HomeButton.png'
import NavigationButton from 'components/NavigationButton'
import styles from './styles.module.scss'

const HomeButton = ({ beforeRedirect, className }) => {
  return (
    <NavigationButton
      className={cn(styles.container, className)}
      beforeRedirect={beforeRedirect}
      to="/"
    >
      <img src={homeButtonImage} alt="home" />
    </NavigationButton>
  )
}

export default HomeButton
