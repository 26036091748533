import ReactLoading from 'react-loading'
import styles from './styles.module.scss'

const LoadingOverlay = ({ children, loading }) => {
  return loading ? (
    <ReactLoading className={styles.loader} type="bubbles" color="#fff" />
  ) : (
    children
  )
}

export default LoadingOverlay
