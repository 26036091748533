import backButtonImageSrc from 'assets/back-button.png'

const PrevPageButton = ({ onClick, className }) => (
  <div className={className} onClick={onClick}>
    <img
      src={backButtonImageSrc}
      alt="prev"
      style={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      }}
    />
  </div>
)

export default PrevPageButton
