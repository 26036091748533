import React from 'react'
import PropTypes from 'prop-types'
import backButtonImageSrc from 'assets/back-button.png'
import noop from 'lodash/noop'
import cn from 'classnames'
import styles from './styles.module.scss'

const propTypes = {
  onClick: PropTypes.func,
}

const defaultProps = {
  onClick: noop,
}

const BackButton = ({ onClick, className }) => (
  <div className={cn(styles.container, className)} onClick={onClick}>
    <img src={backButtonImageSrc} alt="back" />
  </div>
)

BackButton.propTypes = propTypes
BackButton.defaultProps = defaultProps

export default BackButton
