import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingOverlay from 'components/LoadingOverlay'
import * as classroomActions from 'actions/classroom'
import { useHistory } from 'react-router-dom'
import api from 'api'
import ButtonsCardLayout from 'components/ButtonsCardLayout'
import cn from 'classnames'
import { useReaderProvider } from 'context/ReaderContext'
import onebadger from 'assets/onebadger.png'
import twobadgers from 'assets/twobadgers.png'
import threebadgers from 'assets/threebadgers.png'
import styles from './styles.module.scss'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NO_POWERTOOLS_LICENSE } from 'utils/defaults'

const StartPage = (props) => {
  const history = useHistory()
  const auth0 = useAuth0()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  const [subscriptionStatus, setSubscriptionStatus] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    setReading,
    setBuddyMode,
    setIndividualMode,
    setGroupMode,
    setMode
  } = useReaderProvider()

  const [animating, setAnimating] = useState(false)
  const [singleStudent, setSingleStudent] = useState(false)

  const modes = [
    {
      name: 'Individual',
      image: onebadger,
      modeSetter: setIndividualMode,
      redirectTo: '/get-started',
    },
    {
      name: 'Buddy',
      image: twobadgers,
      modeSetter: setBuddyMode,
      redirectTo: '/get-started',
    },
    {
      name: 'Group',
      image: threebadgers,
      modeSetter: setGroupMode,
      redirectTo: '/pick-level',
    },
  ]

  useEffect(() => {
    setReading(false)
    auth0.getAccessTokenSilently().then(async (token) => {
      await api.get('/api/check/subscription', {
        headers: { authorization: `Bearer ${token}` },
      }).then((response)=>{
        console.log("subscription", response.data.status)
        if (["NOTSUBSCRIBED", "EXPIRED"].includes(response.data.status)) {
          setSubscriptionStatus(response.data.status)
          toggle()
        }
      })
      const { data } = await api.get('/api/get/students', {
        headers: { authorization: `Bearer ${token}` },
      })
      setSingleStudent(data.students.length <= 1)
      const teacherID = data.students?.[0].teacherID || data.teacher
      dispatch(classroomActions.setStudents(data.students))
      dispatch(classroomActions.setTeacher({ id: teacherID }))
      api
        .get(`/api/get/themes/${teacherID}`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((r) => {
          dispatch(classroomActions.setThemes(r.data?.reading_levels))
          setLoading(false)
        })
    }).catch((err) => {
      if (err) {
        auth0.logout({ returnTo: window.location.origin })
        history.push('/login')
      }
    })
  }, [auth0, dispatch])

  const closeAndLogout = () => {

    toggle()
    auth0.logout({ returnTo: window.location.origin })
    history.push('/login')
  }

  const closeBtn = (
    <button className="close" onClick={closeAndLogout} type="button">
      &times;
    </button>
  );

  return (
    <LoadingOverlay loading={loading}>
      { subscriptionStatus && 
        <Modal isOpen={modal} toggle={toggle} backdrop='static'>
          <ModalHeader toggle={toggle} close={closeBtn}>{NO_POWERTOOLS_LICENSE[subscriptionStatus]["header"]}</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-12'>
                {NO_POWERTOOLS_LICENSE[subscriptionStatus]["body"]}
              </div>
              <div className='col-12 py-3'>
                <a className="btn btn-danger btn-block" href="mailto:info@toolsofthemind.org">Contact Support</a>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }
      <ButtonsCardLayout animating={animating}>
        <ButtonsCardLayout.Body>
          {modes.map(({ modeSetter, name, image, redirectTo }, index) => (
            <ButtonsCardLayout.Button
              beforeRedirect={(evt) => {
                setAnimating(true)
                setTimeout(() => {
                  modeSetter(evt)
                  setMode(name)
                  setAnimating(false)
                  history.push(redirectTo)
                }, 800)
              }}
              disabled={singleStudent && name === 'Buddy' ? true : false}
              contentLeft={<img src={image} alt={name} />}
              contentCenter={`${name} mode`}
              className="blue text-white"
              key={name}
            />
          ))}
        </ButtonsCardLayout.Body>
        <div className={cn(styles.footerText, 'text-muted')}>
          Logged in as: {auth0.user.nickname}
        </div>
      </ButtonsCardLayout>
    </LoadingOverlay>
  )
}

export default StartPage
