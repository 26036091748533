import { types } from 'actions/book'

const initState = {
  currentWordIndex: 0,
  currentPageIndex: 0,
  currentWordTries: 0,
  pageWordTries: {},
  pageDone: false,
  events: [],
  globalWordTries: {},
}

const bookReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case types.ADD_EVENT:
      return {
        ...state,
        events: [...state.events, payload],
      }
      
    case types.CLEAR:
      return { ...initState }
        
    case types.NEXT_PAGE:
      const next = state.currentPageIndex + 1
      return {
        ...state,
        pageWordTries: {},
        pageDone: false,
        currentPageIndex: next,
    }

    case types.PREV_PAGE:
      const prev = state.currentPageIndex - 1
      return {
        ...state,
        pageWordTries: {},
        pageDone: false,
        currentPageIndex: prev,
    }
    
    case types.LAST_PAGE:
      return {
        ...state,
        pageWordTries: {},
        pageDone: false,
        currentPageIndex: payload,
    }
    
    case types.TOOLBOX:
      let wordTries = state.currentWordTries
      if (payload?.scaffoldData?.order === wordTries) {
        wordTries++
      }
      return {
        ...state,
        pageWordTries: {
          ...state.pageWordTries,
          [state.currentWordIndex]: wordTries,
        },
        currentWordTries: wordTries,
        toolbox: payload.scaffoldData && payload,
        events: [...state.events, { tryNumber: wordTries, ...payload }],
      }

    case types.READ_WORD:
      const { wordIdx } = payload
      return {
        ...state,
        currentWordIndex: wordIdx,
        events: [...state.events, payload],
        toolbox: null,
        currentWordTries: state.pageWordTries?.[wordIdx] || 0,
      }

    case types.SET_WORD_INDEX:
      return {
        ...state,
        currentWordIndex: payload,
      }

    case types.SET_PAGE_DONE:
      return { ...state, pageDone: payload }

    default:
      return state
  }
}

export default bookReducer
