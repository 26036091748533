import axios from 'axios'
import { totmContentBaseUrl } from 'config'

export const TargetTypes = {
  SIGHT_WORD: 'SIGHT_WORD',
  WORD_PATTERN: 'WORD_PATTERN',
}

function BookInfoHelper({ bookId, category, readability, readers, teacherID }) {
  const basePath = [readability, category, bookId].join('/')

  const createComprehensionCheck = (props) => ({
    challengeType: `ComprehensionCheckType${props.type}`,
    ...props,
  })

  const createDecodingCheck = (props) => ({
    challengeType: `DecodingCheck`,
    ...props,
  })

  const fetchInfoJson = () => {
    return axios
      .get(`${totmContentBaseUrl}/Readability/${basePath}/info.json`)
      .then(({ data }) => data)
  }

  this.getTargets = () => {
    const { targets: configTargets, reading_level } = this.config
    if (configTargets.length) {
      if (reading_level === 'B') {
        return { targetSightWords: configTargets }
      }
      if (reading_level === 'C') {
        return { targetWordPatterns: configTargets }
      }
    }
    return {
      targetSightWords: [],
      targetWordPatterns: [],
    }
  }

  this.requestMediaFile = (path) => {
    path = path.replace(/\.aiff$/, '.mp3')
    return `${totmContentBaseUrl}/Readability/${basePath}/${path}`
  }

  this.getPageLength = () =>{
    return this.config.pages.length
  }

  this.getPageItems = () => {
    return this.config.pages.map(
      ({ read_aloud, background, ...info }, index) => ({
        ...info,
        id: index,
        backgroundImageSrc: this.requestMediaFile(background),
        readAloudAudioSrc: this.requestMediaFile(read_aloud),
      })
    )
  }

  this.getChallenges = (numReaders) => {
    return [
      createComprehensionCheck({
        challengeKey: 'comprehensionCheck',
        ...this.config.comprehension_check,
      }),
      numReaders > 1 &&
        createComprehensionCheck({
          challengeKey: 'comprehensionCheck2',
          ...this.config.comprehension_check2,
        }),
      createDecodingCheck({
        challengeKey: 'decodingCheck',
        ...this.config.comprehension_record,
      }),
      numReaders > 1 &&
        createDecodingCheck({
          challengeKey: 'decodingCheck2',
          ...this.config.comprehension_record2,
        }),
    ].filter(Boolean)
  }

  this.init = async () => {
    this.config = {
      targets: [],
      ...(await fetchInfoJson()),
    }
    return this
  }
}

export default BookInfoHelper
