import SelectionCardGrid, {
  SelectionCardGridItem,
} from 'components/SelectionCardGrid'
import { totmContentBaseUrl } from 'config'
import { escapePathForURL } from 'globalMethods'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styles from './styles.module.scss'

export default () => {
  const { readability, category } = useParams()
  const history = useHistory()
  const themes = useSelector((s) => s.classroom.themes)
  const { books } = themes[readability].themes[category]

  return (
    <SelectionCardGrid>
      {Object.values(books)
        .filter((o) => o.enabled)
        .map(({ name, path, thumbnail }) => (
          <SelectionCardGridItem
            className={styles.bookCard}
            key={name}
            onClick={() => {
              history.push(
                `/levels/${readability}/category/${category}/reader/${escapePathForURL(name)}`
              )
            }}
            coverImage={`${totmContentBaseUrl}/${escapePathForURL(thumbnail)}`}
          />
        ))}
    </SelectionCardGrid>
  )
}
