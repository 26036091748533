import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import ReadAloudButton from 'components/ReadAloudButton'
import { useDispatch } from 'react-redux'
import { ScaffoldTypes, maxScaffoldTries } from 'config'
import cn from 'classnames'
import toolboxClosed from 'assets/Toolbox-Closed@2x.png'
import toolboxOpen from 'assets/Toolbox-Open@2x.png'
import orderBy from 'lodash.orderby'

const Images = {
  // Both ../../../assets and assets will work without need for .default like before
  SeenBefore: require('assets/HaveISeenitBefore_Button@2x.png'),
  WordPattern: require('assets/BuildaWord_WordPattern_Button@2x.png'),
  SoundBySound: require('assets/BuildaWord_SoundBySound_Button@2x.png'),
}

const propTypes = {
  wordIdx: PropTypes.number,
}

const defaultProps = {
  scaffolds: {},
  handleClick: () => {},
  wordIdx: 0,
}

const getOrderedScaffolds = (scaffolds) => {
  let scaffoldsList = []
  Object.entries(scaffolds).forEach(([scaffoldType, o]) => {
    if (scaffoldType !== ScaffoldTypes.READ_ALOUD) {
      scaffoldsList.push({ scaffoldType, ...o })
    }
  })
  return orderBy(scaffoldsList, 'order')
}

const Toolbar = ({
  className,
  bookInfo,
  currentWordTries,
  scaffolds,
  currentPageIndex: pageIndex,
  currentWordIndex: wordIndex,
  handleClick,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [onlyReadAloud, setOnlyReadAloud] = React.useState(false)
  const [animateNotAllowed, setAnimateNotAllowed] = React.useState(false)

  const targetScaffoldType = React.useMemo(() => {
    return getOrderedScaffolds(scaffolds)[currentWordTries]?.scaffoldType
  }, [getOrderedScaffolds, scaffolds, currentWordTries])

  const isOnlyReadAloud =
    !targetScaffoldType || currentWordTries >= maxScaffoldTries

  const isValidScaffold = (key) => {
    return key === targetScaffoldType || key === ScaffoldTypes.READ_ALOUD
  }

  const toggle = () => {
    setOnlyReadAloud(isOnlyReadAloud)
    setOpen((v) => !v)
  }

  const handleScaffoldClick = (key) => {
    if (animateNotAllowed) {
      return
    }
    if (isValidScaffold(key)) {
      handleClick(key, scaffolds[key])
      return setOpen(false)
    }
    handleClick(key, null)
    setAnimateNotAllowed(key)
    setTimeout(() => {
      setOnlyReadAloud(isOnlyReadAloud)
    }, 800)
    setTimeout(() => {
      setAnimateNotAllowed(false)
      //      setOpen(false)
    }, 800)
  }

  const renderScaffold = (component, key) => (
    <div
      key={key}
      onClick={() => handleScaffoldClick(key)}
      className={cn(
        { [styles.wiggle]: animateNotAllowed === key },
        styles.toolbarButtonContainer
      )}
    >
      {component}
    </div>
  )

  React.useEffect(() => {
    if (open && isOnlyReadAloud) {
      handleClick(ScaffoldTypes.READ_ALOUD, scaffolds[ScaffoldTypes.READ_ALOUD])
    }
  }, [open, onlyReadAloud])

  const renderScaffolds = () => {
    if (onlyReadAloud) {
      return renderScaffold(
        <ReadAloudButton
          className={styles.toolbarButtonImage}
          onClick={() => {}}
          src={
            scaffolds.ReadAloud
              ? bookInfo.requestMediaFile(scaffolds.ReadAloud.audio)
              : null
          }
        />,
        ScaffoldTypes.READ_ALOUD
      )
    }
    return Object.entries(Images).map(([key, value]) =>
      renderScaffold(
        <img
          className={cn(styles.toolbarButtonImage, {
            [styles.flash]: animateNotAllowed && isValidScaffold(key),
          })}
          src={value}
          alt={key}
        />,
        key
      )
    )
  }

  React.useEffect(() => setOpen(false), [pageIndex, wordIndex])

  return (
    <div className={cn(styles.container, className)}>
      <div style={{ display: open ? 'block' : 'none', textAlign:'center' }}>
        {renderScaffolds()}
      </div>
      {[
        [toolboxClosed, !open],
        [toolboxOpen, open],
      ].map(([src, visible], i) => (
        <img
          key={i}
          src={src}
          style={{ display: visible ? 'block' : 'none'}}
          alt=""
          onClick={toggle}
        />
      ))}
    </div>
  )
}

Toolbar.propTypes = propTypes
Toolbar.defaultProps = defaultProps

export default connect((state) => ({ ...state.book }))(Toolbar)
