export const requestMediaFile = (path) => {
  return require.context('assets', true)(path).default
}

export const randInt = (max, min = 0) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getRectCenterPos = (r) => ({
  x: r.x + r.width / 2,
  y: r.y + r.height / 2,
})

export const speak = (s) => {
  window.responsiveVoice.speak(s, "US English Female", {pitch: 1.5, rate:.75})
}
