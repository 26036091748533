// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_loader__dPvHG {\n  position: absolute;\n  width: 200px !important;\n  top: calc(510vh - 100px);\n  left: calc(50vw - 100px);\n}", "",{"version":3,"sources":["webpack://./src/components/LoadingOverlay/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,uBAAA;EACA,wBAAA;EACA,wBAAA;AADF","sourcesContent":["$loader-w: 200px;\n\n.loader {\n  position: absolute;\n  width: $loader-w !important;\n  top: calc(510vh - #{$loader-w / 2});\n  left: calc(50vw - #{$loader-w / 2});\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "styles_loader__dPvHG"
};
export default ___CSS_LOADER_EXPORT___;
