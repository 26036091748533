import cn from 'classnames'
import NavigationButton from 'components/NavigationButton'

import styles from './Button.module.scss'

const Button = ({
  beforeRedirect,
  className,
  contentRight,
  contentCenter,
  contentLeft,
  disabled,
  to,
}) => {
  const _className = 'w-100 btn navigation'

  return (
    <NavigationButton
      disabled={disabled}
      to={to}
      className={cn(
        styles.contentContainer,
        _className,
        'd-flex align-items-center',
        className
      )}
      beforeRedirect={beforeRedirect}
    >
      <div className={styles.contentLeft}>{contentLeft}</div>
      <div className={styles.contentCenter}>{contentCenter}</div>
      <div className={styles.contentRight}>{contentRight}</div>
    </NavigationButton>
  )
}
export default Button
