export const types = {
  NEXT_PAGE: 'book/NEXT_PAGE',
  PREV_PAGE: 'book/PREV_PAGE',
  LAST_PAGE: 'book/LAST_PAGE',
  READ_WORD: 'book/READ_WORD',
  ADD_EVENT: 'book/ADD_EVENT',
  SET_WORD_INDEX: 'book/SET_WORD_INDEX',
  SET_ALL_SCAFFOLDS_TRIED: 'book/SET_ALL_SCAFFOLDS_TRIED',
  SET_PAGE_DONE: 'book/SET_PAGE_DONE',
  TOOLBOX: 'book/TOOLBOX',
  CLEAR: 'book/CLEAR',
}

export const nextPage = () => ({
  type: types.NEXT_PAGE,
})

export const prevPage = () => ({
  type: types.PREV_PAGE,
})

export const lastPage = (payload) => ({
  type: types.LAST_PAGE,
  payload
})

export const readWord = (payload) => ({
  type: types.READ_WORD,
  payload,
})

export const setWordIndex = (payload) => ({
  type: types.SET_WORD_INDEX,
  payload,
})

export const setAllScaffoldsTried = (payload) => ({
  type: types.SET_ALL_SCAFFOLDS_TRIED,
  payload,
})

export const addEvent = (payload) => ({
  type: types.ADD_EVENT,
  payload,
})

export const clear = () => ({
  type: types.CLEAR,
})

export const setPageDone = (payload) => ({
  type: types.SET_PAGE_DONE,
  payload,
})

export const toolbox = (payload) => ({
  type: types.TOOLBOX,
  payload,
})
