import nextPageButtonImageSrc from 'assets/next-page-button.png'

const NextPageButton = ({ onClick, className }) => (
  <div className={className} onClick={onClick}>
    <img
      src={nextPageButtonImageSrc}
      alt="next"
      style={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      }}
    />
  </div>
)

export default NextPageButton
