import { types } from 'actions/classroom'

const initState = {
  students: [],
  teacher: {},
  themes: {},
}

const classroomReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case types.SET_THEMES:
      return {
        ...state,
        themes: payload,
      }

    case types.SET_STUDENTS:
      return {
        ...state,
        students: payload,
      }

    case types.SET_TEACHER:
      return {
        ...state,
        teacher: payload,
      }

    case types.CLEAR:
      return { ...initState }

    default:
      return state
  }
}

export default classroomReducer
