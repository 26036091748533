import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  USER_TYPE_TITLE,
  HOME_USER_TYPE,
  SCHOOL_USER_TYPE,
} from '../../utils/defaults'

import styles from './styles.module.scss'

const LoginAlert = withReactContent(Swal)

const LoginPage = () => {
  const auth0 = useAuth0()
  const history = useHistory()

  const createLoginAlert = (props) => {
    return LoginAlert.fire({
      title: `<strong>${USER_TYPE_TITLE}</strong>`,
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: false,
      ...props,
    })
  }

  const handleLoginError = (e) => {
    createLoginAlert({
      icon: 'error',
      text: 'Error signing in...',
    })
  }

  const handleLoginSuccess = () => {
    history.push('/')
  }

  const loginMethod = (loginChoice) => {
    LoginAlert.close()
    return auth0
      .loginWithPopup(loginChoice)
      .then(handleLoginSuccess)
      .catch(handleLoginError)
  }

  const loginChoices = (
    <div>
      <div className={styles.loginChoice}>
        <span className="h6" onClick={() => loginMethod()}>
          {SCHOOL_USER_TYPE} <FontAwesomeIcon icon="school" className="ml-3" />
        </span>
      </div>
      <div className={styles.loginChoice}>
        <span
          className="h6"
          onClick={() => loginMethod({ connection: 'ToolsAtHome' })}
        >
          {HOME_USER_TYPE} <FontAwesomeIcon icon="home" className="ml-3" />
        </span>
      </div>
    </div>
  )

  if (auth0.isAuthenticated) {
    handleLoginSuccess()
    return null
  }

  return (
    <div className="centered-card-layout">
      <div
        className={styles.loginButton}
        type="button"
        onClick={() => {
          createLoginAlert({ icon: 'question', html: loginChoices })
        }}
      >
        Login
      </div>
    </div>
  )
}

export default LoginPage
